import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { CompanyNameSchema, WebsiteUrlSchema } from './property.models.ts'
import { BrandIdSchema } from './unique-id.models.ts'

/**
 * AffiliateId
 */
export const [AffiliateIds, AffiliateIdSchema, AffiliateIdEnum] = EnumBuilder(
  'mntn',
  'meta',
  'tiktok'
)
export type AffiliateId = z.infer<typeof AffiliateIdSchema>

/**
 * BrandDomainSelectModel
 */
export const BrandDomainSelectModelSchema = z.object({
  brandId: BrandIdSchema,
  companyName: CompanyNameSchema,
  companyWebsiteUrl: WebsiteUrlSchema.optional(),
})

export type BrandDomainSelectModel = z.infer<
  typeof BrandDomainSelectModelSchema
>

/**
 * BrandDomainInsertModel
 */
export const BrandDomainInsertModelSchema =
  BrandDomainSelectModelSchema.partial({
    brandId: true,
  })

export type BrandDomainInsertModel = z.infer<
  typeof BrandDomainInsertModelSchema
>

/**
 * BrandDomainUpdateModel
 */
export const BrandDomainUpdateModelSchema = BrandDomainSelectModelSchema.omit({
  brandId: true,
})
  .extend({
    companyWebsiteUrl:
      BrandDomainSelectModelSchema.shape.companyWebsiteUrl.nullable(),
  })
  .partial()

export type BrandDomainUpdateModel = z.infer<
  typeof BrandDomainUpdateModelSchema
>

/**
 * BrandDomainSelectModelFilters
 */
export const BrandDomainSelectModelFiltersSchema = z
  .object({
    brandId: ModelFilterSchema(BrandIdSchema),
    // When filtering, transform company names to lower case so the
    // "lower(brands.company_name)" index can be used.
    companyName: ModelFilterSchema(CompanyNameSchema.toLowerCase()),
  })
  .partial()

export type BrandDomainSelectModelFilters = z.infer<
  typeof BrandDomainSelectModelFiltersSchema
>
