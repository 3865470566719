import type { Simplify, TupleToUnion } from 'type-fest'
import { z } from 'zod'
import type { NonEmptyArray } from './utility-types.ts'

/**
 *
 * @param values the list of values to create an enum from
 * @returns a tuple containing the enum values, the zod schema for validating the enum, and a map object for all the enum values
 */
export const EnumBuilder = <
  Items extends string,
  Values extends Readonly<NonEmptyArray<Items>>,
>(
  ...values: Values
) => {
  const schema = z.enum(values)

  const map = values.reduce(
    (map, value) => Object.assign(map, { [value]: value }),
    {} as Simplify<Readonly<{ [Value in TupleToUnion<Values>]: Value }>>
  )

  return Object.assign(<const>[values, schema, map], {
    default: <Value extends TupleToUnion<Values>>(value: Value) =>
      <const>[values, schema.default(value), map],
    catch: <Value extends TupleToUnion<Values>>(value: Value) =>
      <const>[values, schema.catch(value), map],
  })
}
