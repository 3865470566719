// This error should be thrown in the default case of a switch statement (or after a series
// of if statements) to ensure that all values of a union have been handled. It is intended
// to be used in conjunction with TypeScript's "strict" mode to ensure that all values of a
// union type are handled. The typical usage of this is:
//
// switch (discriminator) {
//   case 'value1': {
//     // handle value1
//     return
//   }
//
//   case 'value2': {
//     // handle value2
//     return
//   }
//
//   default: {
//     throw new UnhandledUnionError(discriminator)
//   }
// }
//
// Normally, this will be caught by TypeScript's type checker if not all values are handled to
// prevent runtime errors. However, if you actually want to throw an error at runtime, you can
// use this error class to do so by casting the discriminator value as `never` which will suppress
// the type error and allow the code to compile. This is useful in cases where you are confident
// that a value should never be reached and want to throw an error if it is.
//
// This can also be used in conjunction with a series of if statements to ensure that all union
// values are handled. For example:
//
// if (discriminator === 'value1') {
//   // handle value1
//   return
// }
//
// if (discriminator === 'value2') {
//   // handle value2
//   return
// }
//
// throw new UnhandledUnionError(discriminator)
//

export class UnhandledUnionError extends TypeError {
  constructor(value: never) {
    super(`Unhandled union value: ${value}`)
  }
}
