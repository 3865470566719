import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { JsonValueSchema, NonEmptyStringSchema } from './property.models.ts'
import {
  OfferIdSchema,
  ProjectIdSchema,
  SurveyIdSchema,
  UserIdSchema,
} from './unique-id.models.ts'

/**
 * SurveyRating
 */
export const SurveyRatingSchema = z.number().int().min(1).max(5)

/**
 * SurveyType
 */
export const [SurveyTypes, SurveyTypeSchema, SurveyTypeEnum] = EnumBuilder(
  'project-completed',
  'offer-accepted',
  'offer-declined'
)
export type SurveyType = z.infer<typeof SurveyTypeSchema>

export const SurveySubjectSchema = z.discriminatedUnion('surveyType', [
  z.object({
    surveyType: SurveyTypeSchema.extract(['project-completed']),
    projectId: ProjectIdSchema,
  }),
  z.object({
    surveyType: SurveyTypeSchema.extract(['offer-accepted', 'offer-declined']),
    offerId: OfferIdSchema,
  }),
])

/**
 * SurveyDomainSelectModel
 */
export const SurveyDomainSelectModelSchema = z.object({
  surveyId: SurveyIdSchema,
  responderId: UserIdSchema,
  subject: SurveySubjectSchema,
  responses: z.record(NonEmptyStringSchema(), JsonValueSchema.optional()),
})

export type SurveyDomainSelectModel = z.infer<
  typeof SurveyDomainSelectModelSchema
>

/**
 * SurveyDomainInsertModel
 */
export const SurveyDomainInsertModelSchema =
  SurveyDomainSelectModelSchema.partial({ surveyId: true })

export type SurveyDomainInsertModel = z.infer<
  typeof SurveyDomainInsertModelSchema
>

/**
 * SurveyDomainSelectModelFilters
 */
export const SurveyDomainSelectModelFiltersSchema = z
  .object({
    surveyId: ModelFilterSchema(SurveyIdSchema),
    surveyType: ModelFilterSchema(SurveyTypeSchema),
    responderId: ModelFilterSchema(UserIdSchema),
    subject: ModelFilterSchema(SurveySubjectSchema),
  })
  .partial()

export type SurveyDomainSelectModelFilters = z.infer<
  typeof SurveyDomainSelectModelFiltersSchema
>
