import { type AnyRecord, EnumBuilder } from '@mntn-dev/utility-types'

export const [LogLevels, LogLevelSchema, LogLevelEnum] = EnumBuilder(
  'error',
  'warn',
  'info',
  'debug'
)
export type LogLevel = (typeof LogLevels)[number]
export const isLogLevel = (value?: string): value is LogLevel =>
  value !== undefined && LogLevels.includes(value as LogLevel)

export type LoggerFunction = (message: string, args?: AnyRecord<string>) => void
export type LoggerInstance = {
  [level in LogLevel]: LoggerFunction
}
