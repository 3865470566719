import type { FlatNamespace, InitOptions } from 'i18next'

import { LanguageIds, type LanguageId } from '@mntn-dev/domain-types'

import { defaultNS, fallbackLanguageId } from './settings.ts'

export function getOptions(
  lng: LanguageId = fallbackLanguageId,
  ns: FlatNamespace = defaultNS,
  languageIds: LanguageId[] = [...LanguageIds],
  defNS: FlatNamespace = defaultNS,
  defLanguageId: LanguageId = fallbackLanguageId
): InitOptions {
  const result = {
    supportedLngs: languageIds,
    fallbackLng: defLanguageId,
    lng,
    fallbackNS: defNS,
    defaultNS: defNS,
    ns,
    /**
     * As of version 21.3.0, i18next has built in number formatting based on Intl API.
     * See: https://www.i18next.com/translation-function/formatting
     */
  }
  return result
}
