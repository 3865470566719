import { z } from 'zod'
import {
  NameSchema,
  ReviewRoundNumberSchema,
  ReviewStatusSchema,
} from '../property.models.ts'
import { PreProductionReviewKeySchema } from '../review.models.ts'
import { RoundStatusSchema } from '../round.models.ts'
import { ReviewIdSchema, RoundIdSchema } from '../unique-id.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const PreProductionReviewStatusChangedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'pre_production_review_status_changed',
    z.object({
      reviewId: ReviewIdSchema,
      reviewKey: PreProductionReviewKeySchema,
      displayName: NameSchema,
      reviewStatus: ReviewStatusSchema,
      roundId: RoundIdSchema,
      roundNumber: ReviewRoundNumberSchema,
      roundStatus: RoundStatusSchema,
    })
  )
