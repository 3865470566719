/**
 * Converts an object to a string record.
 *
 * This function is useful when you want to convert an object to a record that can be used as a query string.
 * It will convert all primitive values to strings, except for null and undefined values.
 *
 * @param obj  The object to convert to a string record.
 * @returns  A string record that can be used as a query string.
 */
export function toStringRecord(
  obj: Record<string, unknown>
): Record<string, string> {
  return Object.entries(obj)
    .filter(([, value]) =>
      ['string', 'number', 'boolean'].includes(typeof value)
    )
    .reduce(
      (acc, [key, value]) => {
        acc[key] = String(value)
        return acc
      },
      {} as Record<string, string>
    )
}
