import type { LogLevel, LoggerFunction, LoggerInstance } from './log-types.ts'
import { LogLevels, isLogLevel } from './log-types.ts'

export const FilteredLogLevel = Object.fromEntries(
  LogLevels.map((level, index) => [level, index])
) as Record<LogLevel, number>

const shouldLog = (logLevel: LogLevel) => {
  const filterLevel = isLogLevel(process.env.LOG_LEVEL)
    ? process.env.LOG_LEVEL
    : isLogLevel(process.env.NEXT_PUBLIC_LOG_LEVEL)
      ? process.env.NEXT_PUBLIC_LOG_LEVEL
      : 'error'
  return FilteredLogLevel[filterLevel] >= FilteredLogLevel[logLevel]
}

const createFilteredLogger = (
  innerLogger: LoggerInstance,
  level: LogLevel
): LoggerFunction => {
  return (message, args) =>
    shouldLog(level) && innerLogger[level](message, args)
}

export const FilteredLogger = (innerLogger: LoggerInstance): LoggerInstance => {
  return {
    error: createFilteredLogger(innerLogger, 'error'),
    warn: createFilteredLogger(innerLogger, 'warn'),
    info: createFilteredLogger(innerLogger, 'info'),
    debug: createFilteredLogger(innerLogger, 'debug'),
  }
}
