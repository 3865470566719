import { type IdTag, idTags } from './models/unique-id.models.ts'

export const parseUrn = (urn: string) => {
  const [prefix, nid, nss, ...extra] = urn.split(':')

  return prefix === 'urn' &&
    nid &&
    nss &&
    (idTags as readonly string[]).includes(nid)
    ? { prefix, nid: nid as IdTag, nss, exact: extra.length === 0 }
    : undefined
}
