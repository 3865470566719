import { z } from 'zod'
import {
  archiveMimeTypes,
  audioMimeTypes,
  codeMimeTypes,
  documentMimeTypes,
  fontMimeTypes,
  imageMimeTypes,
  otherMimeTypes,
  videoMimeTypes,
} from './mime-data.ts'
import { MimeTypeBuilder } from './mime-type-builder.ts'

/**
 * Document File Content Types as Extensions
 */
export const [
  documentFileContentTypes,
  documentFileExtensions,
  documentFileContentSchema,
  documentFileExtensionSchema,
] = MimeTypeBuilder(documentMimeTypes)
export type DocumentContentType = z.infer<typeof documentFileContentSchema>
export type DocumentExtension = z.infer<typeof documentFileExtensionSchema>

/**
 * Image File Content Types as Extensions
 */
export const [
  imageFileContentTypes,
  imageFileExtensions,
  imageFileContentSchema,
  imageExtensionsSchema,
] = MimeTypeBuilder(imageMimeTypes)
export type ImageContentType = z.infer<typeof imageFileContentSchema>
export type ImageExtension = z.infer<typeof imageExtensionsSchema>

/**
 * Audio File Content Types as Extensions
 */
export const [
  audioFileContentTypes,
  audioFileExtensions,
  audioFileContentSchema,
  audioExtensionsSchema,
] = MimeTypeBuilder(audioMimeTypes)
export type AudioContentType = z.infer<typeof audioFileContentSchema>
export type AudioExtension = z.infer<typeof audioExtensionsSchema>

/**
 * Video File Content Types as Extensions
 */
export const [
  videoFileContentTypes,
  videoFileExtensions,
  videoFileContentSchema,
  videoExtensionsSchema,
] = MimeTypeBuilder(videoMimeTypes)
export type VideoContentType = z.infer<typeof videoFileContentSchema>
export type VideoExtension = z.infer<typeof videoExtensionsSchema>

/**
 * Archive File Content Types as Extensions
 */
export const [
  archiveFileContentTypes,
  archiveFileExtensions,
  archiveFileContentSchema,
  archiveExtensionsSchema,
] = MimeTypeBuilder(archiveMimeTypes)
export type ArchiveContentType = z.infer<typeof archiveFileContentSchema>
export type ArchiveExtension = z.infer<typeof archiveExtensionsSchema>

/**
 * Font File Content Types as Extensions
 */
export const [
  fontFileContentTypes,
  fontFileExtensions,
  fontFileContentSchema,
  fontExtensionsSchema,
] = MimeTypeBuilder(fontMimeTypes)
export type FontContentType = z.infer<typeof fontFileContentSchema>
export type FontExtension = z.infer<typeof fontExtensionsSchema>

/**
 * Code File Content Types as Extensions
 */
export const [
  codeFileContentTypes,
  codeFileExtensions,
  codeFileContentSchema,
  codeExtensionsSchema,
] = MimeTypeBuilder(codeMimeTypes)
export type CodeContentType = z.infer<typeof codeFileContentSchema>
export type CodeExtension = z.infer<typeof codeExtensionsSchema>

/**
 * Other File Content Types as Extensions
 */
export const [
  otherFileContentTypes,
  otherFileExtensions,
  otherFileContentSchema,
  otherExtensionsSchema,
] = MimeTypeBuilder(otherMimeTypes)
export type OtherContentType = z.infer<typeof otherFileContentSchema>
export type OtherExtension = z.infer<typeof otherExtensionsSchema>

/**
 * Combined Content Types as Extensions
 */

export const FileContentTypeSchema = z.enum([
  ...archiveFileContentTypes,
  ...audioFileContentTypes,
  ...codeFileContentTypes,
  ...documentFileContentTypes,
  ...fontFileContentTypes,
  ...imageFileContentTypes,
  ...otherFileContentTypes,
  ...videoFileContentTypes,
])
export type FileContentType = z.infer<typeof FileContentTypeSchema>

/**
 * Combined File Extensions
 */
const FileExtensionSchema = z.enum([
  ...archiveFileExtensions,
  ...audioFileExtensions,
  ...codeFileExtensions,
  ...documentFileExtensions,
  ...fontFileExtensions,
  ...imageFileExtensions,
  ...otherFileExtensions,
  ...videoFileExtensions,
])
export type FileExtension = z.infer<typeof FileExtensionSchema>
