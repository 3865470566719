import {
  type ParsedPhoneNumber,
  type PhoneNumberPossibility,
  parsePhoneNumber as awesomeParsePhoneNumber,
} from 'awesome-phonenumber'

type PhoneValidationResult =
  | 'too-short'
  | 'too-long'
  | 'invalid-country-code'
  | 'invalid-phone-number'
  | 'valid'

const safePossibilities: PhoneNumberPossibility[] = [
  'unknown',
  'too-short',
  'is-possible',
]

const parsePhoneNumber = (
  phoneNumber: string,
  regionCode = 'US'
): ParsedPhoneNumber => awesomeParsePhoneNumber(phoneNumber, { regionCode })

const isPhoneNumberCompleteAndValid = (phoneNumber: string): boolean => {
  const { valid } = parsePhoneNumber(phoneNumber)

  return valid
}

const isPhoneNumberPossiblyValid = (phoneNumber: string): boolean => {
  const { possibility } = parsePhoneNumber(phoneNumber)

  return safePossibilities.includes(possibility)
}

const validatePhoneNumber = (phoneNumber: string): PhoneValidationResult => {
  const { valid, possibility } = parsePhoneNumber(phoneNumber)

  if (valid) {
    return 'valid'
  }

  if (possibility === 'unknown' && phoneNumber.length <= 1) {
    return 'too-short'
  }

  if (
    possibility === 'is-possible' ||
    possibility === 'invalid' ||
    possibility === 'unknown'
  ) {
    return 'invalid-phone-number'
  }

  return possibility
}

export {
  isPhoneNumberCompleteAndValid,
  isPhoneNumberPossiblyValid,
  parsePhoneNumber,
  validatePhoneNumber,
}
