import { ActivityDataTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

import { z } from 'zod'

export const PreProductionCompletedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'pre_production_completed',
    z.object({
      project: ActivityDataTargetSchema,
    })
  )
