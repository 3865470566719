import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { FileStorageKeySchema } from './file.models.ts'
import {
  CompanyNameSchema,
  EmailAddressSchema,
  NameSchema,
  NonEmptyStringSchema,
  TimestampSchema,
  USPhoneNumberSchema,
  WebsiteUrlSchema,
} from './property.models.ts'
import { TagFilterSchema } from './tag.models.ts'
import {
  AgencyIdSchema,
  BrandIdSchema,
  UserIdSchema,
  UserUrnSchema,
} from './unique-id.models.ts'

/**
 * UserAuthConnection
 */
export const [
  UserAuthConnections,
  UserAuthConnectionSchema,
  UserAuthConnectionEnum,
] = EnumBuilder('email', 'Username-Password-Authentication').catch('email')

export type UserAuthConnection = z.infer<typeof UserAuthConnectionSchema>

/**
 * UserJobFunction
 */

// Job functions that only relate to brand users
const BrandUserJobFunctions = <const>[
  'marketing-leader',
  'marketer',
  'marketing-operations',
  'creative-leader',
  'creative',
  'creative-operations',
  'other',
]

// Job functions that only relate to maker users
// Anticipating this will be a thing in the future, but not yet
const MakerUserJobFunctions = <const>['maker']

// Job functions that only relate to internal users
// Anticipating this will be a thing in the future, but not yet
const InternalUserJobFunctions = <const>['internal']

// Map of user types to job functions
export const UserJobFunctionsByUserTypeMap = {
  brand: BrandUserJobFunctions,
  maker: MakerUserJobFunctions,
  internal: InternalUserJobFunctions,
}

// All job functions
export const [UserJobFunctions, UserJobFunctionSchema, UserJobFunctionEnum] =
  EnumBuilder(
    ...BrandUserJobFunctions,
    ...MakerUserJobFunctions,
    ...InternalUserJobFunctions
  )
export type UserJobFunction = z.infer<typeof UserJobFunctionSchema>

/**
 * Bio
 */
export const BioSchema = z.string().trim().max(4096)
export type Bio = z.infer<typeof BioSchema>

/**
 * UserType
 */
export const ExternalUserTypes = <const>['brand', 'maker']
export const InternalUserTypes = <const>['internal']

export const [UserTypes, UserTypeSchema, UserTypeEnum] = EnumBuilder(
  ...ExternalUserTypes,
  ...InternalUserTypes
)
export type UserType = z.infer<typeof UserTypeSchema>

export const [, ExternalUserTypeSchema, ExternalUserTypeEnum] = EnumBuilder(
  ...ExternalUserTypes
)
export type ExternalUserType = z.infer<typeof ExternalUserTypeSchema>

export function isUserType(value: string): value is UserType {
  return UserTypeSchema.safeParse(value).success
}

// System user type is special and is kept separate to reduce chance of unintentionally elevating permissions
export const [SystemUserTypes, SystemUserTypeSchema, SystemUserTypeEnum] =
  EnumBuilder('system')
export type SystemUserType = z.infer<typeof SystemUserTypeSchema>

/**
 * UserDomainSelectModel
 */
export const UserDomainSelectModelSchema = z.object({
  userId: UserIdSchema,
  userUrn: UserUrnSchema,
  emailAddress: EmailAddressSchema,
  userType: UserTypeSchema,
  firstName: NameSchema,
  lastName: NameSchema,
  displayName: z.string(),
  initials: z.string(),
  avatarStorageKey: FileStorageKeySchema.optional(),
  jobFunction: UserJobFunctionSchema.optional(),
  bio: BioSchema.optional(),
  websiteUrls: WebsiteUrlSchema.array().optional(),
  phoneNumbers: USPhoneNumberSchema.array().optional(), // TODO: QF-2463 - Support international phone numbers
  authConnection: UserAuthConnectionSchema,
  isActive: z.boolean(),
  lastLogin: TimestampSchema.optional(),
  signUpTimestamp: TimestampSchema,
})

export type UserDomainSelectModel = z.infer<typeof UserDomainSelectModelSchema>

/**
 * UserDomainInsertModel
 */
export const UserDomainInsertModelSchema = UserDomainSelectModelSchema.omit({
  userUrn: true,
  isActive: true,
  displayName: true,
  initials: true,
  authConnection: true,
  lastLogin: true,
  signUpTimestamp: true,
}).partial({
  userId: true,
})

export type UserDomainInsertModel = z.infer<typeof UserDomainInsertModelSchema>

/**
 * UserDomainRegistrationModel
 * userId and emailAddress values are extracted from the session object
 */
export const UserDomainRegistrationModelSchema =
  UserDomainInsertModelSchema.omit({
    userId: true,
    emailAddress: true,
  })

export type UserDomainRegistrationModel = z.infer<
  typeof UserDomainRegistrationModelSchema
>

/**
 * UserDomainUpdateModel
 */
export const UserDomainUpdateModelSchema = UserDomainSelectModelSchema.omit({
  userId: true,
  userUrn: true,
  emailAddress: true,
  displayName: true,
  initials: true,
  avatarStorageKey: true,
  jobFunction: true,
  signUpTimestamp: true,
})
  // Certain properties are nullable so they can be removed
  .extend({
    avatarStorageKey:
      UserDomainSelectModelSchema.shape.avatarStorageKey.nullable(),
    jobFunction: UserDomainSelectModelSchema.shape.jobFunction.nullable(),
  })
  .partial()

export type UserDomainUpdateModel = z.infer<typeof UserDomainUpdateModelSchema>

/**
 * ExternalUserDomainUpdateModel
 */
export const ExternalUserDomainUpdateModelSchema =
  UserDomainUpdateModelSchema.extend({
    userType: ExternalUserTypeSchema,
  }).partial()

/**
 * UserDomainSelectModelFilters
 */
export const UserDomainSelectModelFiltersSchema = z
  .object({
    userId: ModelFilterSchema(UserIdSchema),
    userUrn: ModelFilterSchema(UserUrnSchema),
    // When filtering, transform email addresses to lower case so the
    // "lower(users.email_address)" unique index can be used.
    emailAddress: ModelFilterSchema(EmailAddressSchema.toLowerCase()),
    userType: ModelFilterSchema(UserTypeSchema),
    search: NonEmptyStringSchema(),
    includeDeactivated: z.boolean(),
    tags: TagFilterSchema,
  })
  .partial()

export type UserDomainSelectModelFilters = z.infer<
  typeof UserDomainSelectModelFiltersSchema
>

/**
 * TODO: The following schemas/types should probably be moved to the user-service package
 */
export const UserCompanyInsertUpdateSchema = z.object({
  userId: UserIdSchema.optional(),
  userType: UserTypeSchema,
  firstName: NameSchema,
  lastName: NameSchema,
  emailAddress: EmailAddressSchema.optional(),
  companyName: CompanyNameSchema.optional(),
  agencyId: AgencyIdSchema.optional(),
  brandId: BrandIdSchema.optional(),
  jobFunction: UserJobFunctionSchema.optional(),
  bio: BioSchema.optional(),
  websiteUrls: WebsiteUrlSchema.array().optional(),
  phoneNumbers: USPhoneNumberSchema.array().optional(),
})

export type UserCompanyInsertUpdateType = z.infer<
  typeof UserCompanyInsertUpdateSchema
>

export const UserDomainSelectModelWithDeactivateStatusSchema =
  UserDomainSelectModelSchema.extend({
    canBeDeactivated: z.boolean(),
  })

export type UserDomainSelectModelWithDeactivateStatusType = z.infer<
  typeof UserDomainSelectModelWithDeactivateStatusSchema
>
