import type { NonEmptyArray } from '@mntn-dev/utility-types'
import { z } from 'zod'

export function MimeTypeBuilder<T extends Record<string, readonly string[]>>(
  mimeMap: T
) {
  // Extract keys of the object as a strongly typed string array
  const keys = Object.keys(mimeMap) as NonEmptyArray<keyof T & string>

  // Flatten the values of the object into a unique, strongly typed array of extensions
  const extensions = Array.from(
    new Set(Object.values(mimeMap).flat())
  ) as NonEmptyArray<T[keyof T][number]>

  const keysSchema = z.enum(keys as [keyof T & string, ...(keyof T & string)[]])

  const extensionsSchema = z.enum(
    extensions as [T[keyof T][number], ...T[keyof T][number][]]
  )

  return [keys, extensions, keysSchema, extensionsSchema] as const
}
