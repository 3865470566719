import type { MutableRefObject, RefCallback } from 'react'

export const assignRef = <T>(
  ref: RefCallback<T> | MutableRefObject<T> | null | undefined,
  value: T
) => {
  if (ref === null || ref === undefined) {
    return
  }

  if (typeof ref === 'function') {
    ref(value)
  } else {
    ref.current = value
  }
}

export const mergeRefs = <T>(
  ...refs: (RefCallback<T> | MutableRefObject<T> | null | undefined)[]
) => {
  return (value: T) => {
    for (const ref of refs) {
      assignRef(ref, value)
    }
  }
}
