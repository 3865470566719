import { ProjectSubmittedStatusSchema } from '../project.models.ts'
import { ActivityDataTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

import { z } from 'zod'

export const ProjectSubmittedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_submitted',
  z.object({
    project: ActivityDataTargetSchema,
    status: ProjectSubmittedStatusSchema,
  })
)
