import type {
  EmptyObject,
  JsonArray,
  JsonObject,
  JsonPrimitive,
  JsonValue,
} from 'type-fest'
import { z } from 'zod'

import type { NonEmptyArray, PositiveInteger } from '@mntn-dev/utility-types'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { validatePhoneNumber } from '../utils/phone-number/validation.ts'

/**
 * EmptyObject
 */
export const EmptyObjectSchema = z.object<EmptyObject>({}).strict()

/**
 * NonEmptyString
 */
export const NonEmptyStringSchema = <Max extends number>(
  max?: PositiveInteger<Max>,
  messages?: {
    max?: string
    min?: string
  }
) => {
  const nonEmptyString = z.string().trim().min(1, messages?.min)
  return max ? nonEmptyString.max(max, messages?.max) : nonEmptyString
}

/**
 * AgreementCheck
 */
export const AgreementCheckSchema = z.object({
  agreementCheck: z.boolean(),
})

export type AgreementCheck = z.infer<typeof AgreementCheckSchema>

/**
 * PhoneNumber
 */
export const PhoneNumberSchema = z.string().min(7).max(20)
export type PhoneNumber = z.infer<typeof PhoneNumberSchema>

/**
 * CompanyName
 */
export const CompanyNameSchema = NonEmptyStringSchema(256)
export type CompanyName = z.infer<typeof CompanyNameSchema>

/**
 * WebsiteUrl
 */
export const WebsiteUrlSchema = z.string().url()

/**
 * DollarsSchema: a 2-decimal floating point value representing USD
 */
export const DollarsSchema = <Max extends number>(
  max?: PositiveInteger<Max>
) => {
  const schema = z.number().nonnegative()
  return (max !== undefined ? schema.max(max) : schema).transform(
    (val) => Math.round(val * 100) / 100
  )
}

export type Dollars = z.infer<ReturnType<typeof DollarsSchema>>

/**
 * CostMarginPercent
 */
export const CostMarginPercentSchema = z.number().int().nonnegative().max(99)
export type CostMarginPercent = z.infer<typeof CostMarginPercentSchema>

/**
 * Date (or ISO8601 string)
 */

export const DateSchema = z.union([
  z.date(),
  z
    .union([z.string().date(), z.string().datetime()])
    .transform((value) => new Date(value)),
])

/**
 * Description
 */
export const MaxDescriptionLength = 4096
export const DescriptionSchema = NonEmptyStringSchema(MaxDescriptionLength)
export type Description = z.infer<typeof DescriptionSchema>

/**
 * EmailAddress
 */
export const EmailAddressSchema = z.string().email()
export type EmailAddress = z.infer<typeof EmailAddressSchema>

/**
 * LanguageId
 */
export const [LanguageIds, LanguageIdSchema, LanguageIdEnum] = EnumBuilder(
  'en',
  'es'
).default('en')
export type LanguageId = z.infer<typeof LanguageIdSchema>

/**
 * Name
 */
export const MaxNameLength = 256
export const NameSchema = NonEmptyStringSchema(MaxNameLength)
export type Name = z.infer<typeof NameSchema>

/**
 * Note
 */
export const NoteSchema = NonEmptyStringSchema(5000)
export type Note = z.infer<typeof NoteSchema>

/**
 * Phone Number
 */
const E164PhoneNumberSchema = z.string().refine((value) => {
  return typeof value === 'string' ? /^\+\d+$/.test(value) : false
})

export const USPhoneNumberSchema = E164PhoneNumberSchema.superRefine(
  (value, ctx) => {
    if (value.length > 12) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        maximum: 12,
        type: 'string',
        inclusive: true,
        message: 'US phone numbers must be 12 characters long (+1XXXXXXXXXX)',
      })
    }

    if (value.length < 12) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        minimum: 12,
        type: 'string',
        inclusive: true,
        message: 'US phone numbers must be 12 characters long (+1XXXXXXXXXX)',
      })
    }

    const results = validatePhoneNumber(value)

    if (results === 'invalid-country-code') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Only US phone numbers are currently supported',
      })
    }

    if (results !== 'valid') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid US phone number',
      })
    }
  }
)

export type USPhoneNumberSchema = z.infer<typeof USPhoneNumberSchema>

/**
 * ProposalVersion
 */
export const ProposalVersionSchema = z.number().int().nonnegative()
export type ProposalVersion = z.infer<typeof ProposalVersionSchema>

/**
 * ReviewRoundCount
 */
export const ReviewRoundCountSchema = z.number().int().nonnegative()
export type ReviewRoundCount = z.infer<typeof ReviewRoundCountSchema>

/**
 * ReviewRoundNumber
 */
export const ReviewRoundNumberSchema = z.number().int().positive()
export type ReviewRoundNumber = z.infer<typeof ReviewRoundNumberSchema>

/**
 * ReviewStatus
 */
export const [ReviewStatuses, ReviewStatusSchema, ReviewStatusEnum] =
  EnumBuilder('concepting', 'reviewing', 'resolving', 'resolved')
export type ReviewStatus = z.infer<typeof ReviewStatusSchema>

/**
 * Timestamp
 */
export const TimestampSchema = z.date()
export type Timestamp = z.infer<typeof TimestampSchema>

/**
 * Translations
 */
export const TranslationsSchema = <Properties extends NonEmptyArray<string>>(
  ...properties: Properties
) =>
  z.record(
    LanguageIdSchema,
    z.record(z.enum(properties), NonEmptyStringSchema())
  )

/**
 * Url
 */
export const UrlSchema = z.string().url()
export type Url = z.infer<typeof UrlSchema>

/**
 * JsonValue
 * Inspired by https://zod.dev/?id=json-type
 */
export const JsonPrimitiveSchema: z.ZodType<JsonPrimitive> = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.null(),
])

export const JsonValueSchema: z.ZodType<JsonValue> = z.lazy(() =>
  z.union([
    // JsonPrimitive
    JsonPrimitiveSchema,
    // JsonObject
    z.record(JsonValueSchema),
    // JsonArray
    z.array(JsonValueSchema),
  ])
)

export const JsonObjectSchema: z.ZodType<JsonObject> = z.record(JsonValueSchema)
export const JsonArraySchema: z.ZodType<JsonArray> = z.array(JsonValueSchema)
