import { z } from 'zod'

import { NoteSchema, ProposalVersionSchema } from '../property.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const PreProductionBrandFeedbackSubmittedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'pre_production_brand_feedback_submitted',
    z.object({
      approved: z.boolean(),
      version: ProposalVersionSchema,
      note: NoteSchema.optional(),
    })
  )
