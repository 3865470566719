import { z } from 'zod'

import { ActivityDataTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectCustomPriceFinalizedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_custom_price_finalized',
    z.object({
      project: ActivityDataTargetSchema,
    })
  )
