import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  PackagePayoutSchema,
  PackageTranslationsSchema,
} from './package.models.ts'
import {
  CompanyNameSchema,
  CostMarginPercentSchema,
  DateSchema,
  DescriptionSchema,
  DollarsSchema,
  NameSchema,
  NonEmptyStringSchema,
  ReviewRoundCountSchema,
  TimestampSchema,
} from './property.models.ts'
import {
  AgencyIdSchema,
  BrandIdSchema,
  FileIdSchema,
  PackageIdSchema,
  ProjectIdSchema,
  ProjectUrnSchema,
  UserIdSchema,
} from './unique-id.models.ts'

/**
 * ProjectStatus
 */
export const [ProjectStatuses, ProjectStatusSchema, ProjectStatusEnum] =
  EnumBuilder(
    'draft',
    'archived',
    'pricing',
    'priced',
    'submitted',
    'offered',
    'accepted',
    'matched',
    'pre_production',
    'pre_production_complete',
    'production',
    'post_production',
    'post_production_complete',
    'complete'
  )
export type ProjectStatus = z.infer<typeof ProjectStatusSchema>

export const AllExceptArchivedProjectStatuses = ProjectStatuses.filter(
  (status) => status !== 'archived'
)

export const InProgressProjectStatuses: Array<ProjectStatus> = [
  ProjectStatusEnum.pre_production,
  ProjectStatusEnum.pre_production_complete,
  ProjectStatusEnum.production,
  ProjectStatusEnum.post_production,
  ProjectStatusEnum.post_production_complete,
]

export const ProjectSubmittedStatusSchema = ProjectStatusSchema.extract([
  'submitted',
  'pricing',
])
export const ProjectSubmittedStatuses = ProjectSubmittedStatusSchema.options
export type ProjectSubmittedStatus = z.infer<
  typeof ProjectSubmittedStatusSchema
>

/**
 * ProjectDomainSelectModel
 */
export const ProjectDomainSelectModelSchema = z.object({
  projectId: ProjectIdSchema,
  projectUrn: ProjectUrnSchema,
  name: NameSchema,
  description: DescriptionSchema.optional(),
  dueDate: DateSchema.optional(),
  ownerId: UserIdSchema,
  brandId: BrandIdSchema,
  acceptorId: UserIdSchema.optional(),
  agencyId: AgencyIdSchema.optional(),
  status: ProjectStatusSchema,
  thumbnailFileId: FileIdSchema.optional(),
  package: z.object({
    packageId: PackageIdSchema,
    name: NameSchema,
    description: DescriptionSchema,
    translations: PackageTranslationsSchema.optional(),
    cost: PackagePayoutSchema,
    costPlusMargin: DollarsSchema(),
  }),
  costMarginPercent: CostMarginPercentSchema,
  preProductionReviewRounds: ReviewRoundCountSchema.optional(),
  postProductionReviewRounds: ReviewRoundCountSchema.optional(),
})

export type ProjectDomainSelectModel = z.infer<
  typeof ProjectDomainSelectModelSchema
>

/**
 * ProjectViewDomainSelectModelSchema
 */
export const ProjectViewDomainSelectModelSchema =
  ProjectDomainSelectModelSchema.pick({
    projectId: true,
    projectUrn: true,
    name: true,
    description: true,
    dueDate: true,
    ownerId: true,
    brandId: true,
    acceptorId: true,
    agencyId: true,
    status: true,
    preProductionReviewRounds: true,
    postProductionReviewRounds: true,
    thumbnailFileId: true,
    package: true,
    costMarginPercent: true,
  }).extend({
    lastTouchedTimestamp: TimestampSchema,
    brandCompanyName: CompanyNameSchema,
    cost: DollarsSchema(),
    costPlusMargin: DollarsSchema(),
  })

export type ProjectViewDomainSelectModel = z.infer<
  typeof ProjectViewDomainSelectModelSchema
>

/**
 * ProjectDomainInsertModel
 */
export const ProjectDomainInsertModelSchema =
  ProjectDomainSelectModelSchema.omit({
    projectUrn: true,
    thumbnailFileId: true,
  })
    .partial({
      projectId: true,
    })
    .extend({
      costMarginPercent: CostMarginPercentSchema,
      package: ProjectDomainSelectModelSchema.shape.package.omit({
        costPlusMargin: true,
      }),
    })

export type ProjectDomainInsertModel = z.infer<
  typeof ProjectDomainInsertModelSchema
>

/**
 * ProjectDomainCreateFromPackageModel
 */
export const ProjectDomainCreateFromPackageModelSchema =
  ProjectDomainInsertModelSchema.omit({ package: true })

export type ProjectDomainCreateFromPackageModel = z.infer<
  typeof ProjectDomainCreateFromPackageModelSchema
>

/**
 * ProjectDomainUpdateModel
 */
export const ProjectDomainUpdateModelSchema =
  ProjectDomainSelectModelSchema.omit({
    projectId: true,
    projectUrn: true,
    package: true,
    costMarginPercent: true,
  })
    .extend({
      dueDate: ProjectDomainSelectModelSchema.shape.dueDate.nullable(),
      description: ProjectDomainSelectModelSchema.shape.description.nullable(),
      thumbnailFileId:
        ProjectDomainSelectModelSchema.shape.thumbnailFileId.nullable(),
    })
    .partial()

export type ProjectDomainUpdateModel = z.infer<
  typeof ProjectDomainUpdateModelSchema
>

/**
 * ProjectDomainSelectModelFilter
 */
export const ProjectDomainSelectModelFiltersSchema = z
  .object({
    projectId: ModelFilterSchema(ProjectIdSchema),
    projectUrn: ModelFilterSchema(ProjectUrnSchema),
    ownerId: ModelFilterSchema(UserIdSchema),
    brandId: ModelFilterSchema(BrandIdSchema),
    acceptorId: ModelFilterSchema(UserIdSchema),
    agencyId: ModelFilterSchema(AgencyIdSchema),
    status: ModelFilterSchema(ProjectStatusSchema),
    search: NonEmptyStringSchema(),
  })
  .partial()

export type ProjectDomainSelectModelFilters = z.infer<
  typeof ProjectDomainSelectModelFiltersSchema
>
