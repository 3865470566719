import { z } from 'zod'

import { ModelFilterSchema } from '../model-filter-schema.ts'
import { EmptyObjectSchema } from './property.models.ts'
import {
  JacketIdSchema,
  ProjectServiceIdSchema,
  RoundIdSchema,
} from './unique-id.models.ts'

/**
 * JacketDomainSelectModel
 */
export const JacketDomainSelectModelSchema = z.object({
  jacketId: JacketIdSchema,
  roundId: RoundIdSchema,
  projectServiceId: ProjectServiceIdSchema,
})

export type JacketDomainSelectModel = z.infer<
  typeof JacketDomainSelectModelSchema
>

/**
 * JacketDomainInsertModel
 */
export const JacketDomainInsertModelSchema =
  JacketDomainSelectModelSchema.partial({
    jacketId: true,
  })

export type JacketDomainInsertModel = z.infer<
  typeof JacketDomainInsertModelSchema
>

/**
 * JacketDomainUpdateModel
 */
export const JacketDomainUpdateModelSchema = EmptyObjectSchema

export type JacketDomainUpdateModel = z.infer<
  typeof JacketDomainUpdateModelSchema
>

/**
 * JacketDomainSelectModelFilters
 */
export const JacketDomainSelectModelFiltersSchema = z
  .object({
    jacketId: ModelFilterSchema(JacketIdSchema),
    roundId: ModelFilterSchema(RoundIdSchema),
    projectServiceId: ModelFilterSchema(ProjectServiceIdSchema),
  })
  .partial()

export type JacketDomainSelectModelFilters = z.infer<
  typeof JacketDomainSelectModelFiltersSchema
>
