import type { Locale } from 'date-fns'
import { enUS, es } from 'date-fns/locale'

import { LanguageIdEnum, type LanguageId } from '@mntn-dev/domain-types'

export const fallbackLanguageId: LanguageId = LanguageIdEnum.en
export const locales: Record<LanguageId, Locale> = { en: enUS, es } as const
export const fallbackLocale = locales[fallbackLanguageId]

export const defaultNS = 'translation'

export const cookieName = 'i18next'
