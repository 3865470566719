import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  DeliverableIdSchema,
  FileIdSchema,
  JacketIdSchema,
  ProofIdSchema,
  RoundIdSchema,
} from './unique-id.models.ts'

/**
 * ProofStatus
 */
export const [ProofStatuses, ProofStatusSchema, ProofStatusEnum] = EnumBuilder(
  'approved',
  'changes_requested'
)
export type ProofStatus = z.infer<typeof ProofStatusSchema>

/**
 * ProofDomainSelectModel
 */
export const ProofDomainSelectModelSchema = z.object({
  proofId: ProofIdSchema,
  roundId: RoundIdSchema,
  jacketId: JacketIdSchema,
  deliverableId: DeliverableIdSchema,
  fileId: FileIdSchema.optional(),
  status: ProofStatusSchema.optional(),
})

export type ProofDomainSelectModel = z.infer<
  typeof ProofDomainSelectModelSchema
>

/**
 * ProofDomainInsertModel
 */
export const ProofDomainInsertModelSchema =
  ProofDomainSelectModelSchema.partial({
    proofId: true,
  })

export type ProofDomainInsertModel = z.infer<
  typeof ProofDomainInsertModelSchema
>

/**
 * ProofDomainUpdateModel
 */
export const ProofDomainUpdateModelSchema = ProofDomainSelectModelSchema.pick({
  fileId: true,
  status: true,
}).partial()

export type ProofDomainUpdateModel = z.infer<
  typeof ProofDomainUpdateModelSchema
>

/**
 * ProofDomainSelectModelFilters
 */
export const ProofDomainSelectModelFiltersSchema = z
  .object({
    proofId: ModelFilterSchema(ProofIdSchema),
    roundId: ModelFilterSchema(RoundIdSchema),
    jacketId: ModelFilterSchema(JacketIdSchema),
    deliverableId: ModelFilterSchema(DeliverableIdSchema),
    fileId: ModelFilterSchema(FileIdSchema),
    status: ModelFilterSchema(ProofStatusSchema),
  })
  .partial()

export type ProofDomainSelectModelFilters = z.infer<
  typeof ProofDomainSelectModelFiltersSchema
>
