import { z } from 'zod'

import { ModelFilterSchema } from '../model-filter-schema.ts'
import { DollarsSchema } from './property.models.ts'
import {
  ServiceDomainSelectModelSchema,
  ServicePayoutSchema,
  ServiceTypeSchema,
} from './service.models.ts'
import {
  PackageIdSchema,
  PackageServiceIdSchema,
  ServiceIdSchema,
} from './unique-id.models.ts'

/**
 * Count
 */
export const PackageServiceCountSchema = z.number().int().positive()
export type PackageServiceCount = z.infer<typeof PackageServiceCountSchema>

/**
 * PackageServiceDomainSelectModel
 */
export const PackageServiceDomainSelectModelSchema = z.object({
  packageServiceId: PackageServiceIdSchema,
  packageId: PackageIdSchema,
  serviceId: ServiceIdSchema,
  serviceType: ServiceTypeSchema,
  cost: ServicePayoutSchema.optional(),
  costPlusMargin: DollarsSchema().optional(),
  count: PackageServiceCountSchema,
})

export type PackageServiceDomainSelectModel = z.infer<
  typeof PackageServiceDomainSelectModelSchema
>

/**
 * PackageServiceViewDomainSelectModel
 */
export const PackageServiceViewDomainSelectModelSchema =
  PackageServiceDomainSelectModelSchema.merge(
    ServiceDomainSelectModelSchema.omit({ serviceId: true, status: true })
  )

export type PackageServiceViewDomainSelectModel = z.infer<
  typeof PackageServiceViewDomainSelectModelSchema
>

/**
 * PackageServiceDomainInsertModel
 */
export const PackageServiceDomainInsertModelSchema =
  PackageServiceDomainSelectModelSchema.omit({ costPlusMargin: true })
    .extend({
      cost: PackageServiceDomainSelectModelSchema.shape.cost.nullable(),
    })
    .partial({ packageServiceId: true })

export type PackageServiceDomainInsertModel = z.infer<
  typeof PackageServiceDomainInsertModelSchema
>

/**
 * PackageServiceDomainUpdateModel
 */
export const PackageServiceDomainUpdateModelSchema =
  PackageServiceDomainSelectModelSchema.extend({
    cost: PackageServiceDomainSelectModelSchema.shape.cost.nullable(),
  })
    .pick({
      serviceType: true,
      cost: true,
      count: true,
    })
    .partial()

export type PackageServiceDomainUpdateModel = z.infer<
  typeof PackageServiceDomainUpdateModelSchema
>

/**
 * PackageServiceDomainSelectModelFilters
 */
export const PackageServiceDomainSelectModelFiltersSchema = z
  .object({
    packageServiceId: ModelFilterSchema(PackageServiceIdSchema),
    packageId: ModelFilterSchema(PackageIdSchema),
    serviceId: ModelFilterSchema(ServiceIdSchema),
    serviceType: ModelFilterSchema(ServiceTypeSchema),
  })
  .partial()

export type PackageServiceDomainSelectModelFilters = z.infer<
  typeof PackageServiceDomainSelectModelFiltersSchema
>
