import { z } from 'zod'

// a more strict version of the hex color string is too complex for TypeScript
type HexColorTemplate = `#${string}`

export const isHexColorString = (value: string): value is HexColorTemplate => {
  const hexRegex = /^#[0-9A-F]{6}$/i
  return hexRegex.test(value)
}

export const HexColorSchema = z.string().refine(isHexColorString, {
  message: 'Invalid hex color.',
})

export type HexColorString = z.infer<typeof HexColorSchema>
