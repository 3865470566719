import { z } from 'zod'

import {
  OfferDomainQueryModelSchema,
  ProjectDomainQueryModelSchema,
  ProjectServiceDomainQueryModelSchema,
} from '../models/query.models.ts'

const EmptyAclSchema = z.object({})

export const OfferListItemServiceModelSchema =
  OfferDomainQueryModelSchema.extend({
    project: ProjectDomainQueryModelSchema.extend({
      acl: EmptyAclSchema,
    }),
  })

export type OfferListItemServiceModel = z.infer<
  typeof OfferListItemServiceModelSchema
>

export const OfferDetailsServiceModelSchema =
  OfferDomainQueryModelSchema.extend({
    project: ProjectDomainQueryModelSchema.extend({
      acl: EmptyAclSchema,
      services: z.array(
        ProjectServiceDomainQueryModelSchema.extend({ acl: EmptyAclSchema })
      ),
    }),
  })

export type OfferDetailsServiceModel = z.infer<
  typeof OfferDetailsServiceModelSchema
>
