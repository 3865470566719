import { z } from 'zod'

import { uuid } from '@mntn-dev/utilities'

import type { NonEmptyArray } from '@mntn-dev/utility-types'
import type { FileContentType } from './mime-types.ts'
import { EmailAddressSchema } from './property.models.ts'

const fileTypes = ['avatars', 'projectThumbnails'] as const
const FileTypeSchema = z.enum(fileTypes)
export type FileType = z.infer<typeof FileTypeSchema>

export function CreateFilesSchemas<T extends FileType>(
  fileType: T,
  contentTypes: Readonly<NonEmptyArray<FileContentType>>
) {
  const nameSchema = z.string().min(1)
  const keySchema = z.custom<`${T}/${string}`>((val) =>
    val?.startsWith(`${fileType}/`)
  )

  type KeyType = z.infer<typeof keySchema>

  const keyGenerator = (s?: string): KeyType => {
    if (s) {
      const key = keySchema.parse(s)
      return key
    }
    return `${fileType}/${uuid()}`
  }

  const contentTypeSchema = z.enum(contentTypes)

  const downloadInfoSchema = z.object({
    key: keySchema,
  })

  const uploadRequestSchema = z.object({
    fileName: nameSchema,
    contentType: contentTypeSchema,
  })

  const uploadInfoSchema = uploadRequestSchema.extend({
    emailAddress: EmailAddressSchema,
  })

  const uploadResultSchema = z.object({
    fileName: nameSchema,
    key: keySchema,
  })

  return <const>[
    keyGenerator,
    nameSchema,
    keySchema,
    contentTypeSchema,
    downloadInfoSchema,
    uploadRequestSchema,
    uploadResultSchema,
    uploadInfoSchema,
  ]
}

export type FileSchemaType<T extends FileType> = ReturnType<
  typeof CreateFilesSchemas<T>
>
export type NameSchemaIndex = 1
export type KeySchemaIndex = 2
export type ContentTypeSchemaIndex = 3
export type DownloadInfoSchemaIndex = 4
export type UploadRequestSchemaIndex = 5
export type UploadResultSchemaIndex = 6
export type UploadInfoSchemaIndex = 7

export type Infer<
  T extends FileType,
  N extends
    | NameSchemaIndex
    | KeySchemaIndex
    | ContentTypeSchemaIndex
    | DownloadInfoSchemaIndex
    | UploadRequestSchemaIndex
    | UploadResultSchemaIndex
    | UploadInfoSchemaIndex,
> = z.infer<FileSchemaType<T>[N]>
