import { z } from 'zod'
import { ActivityDataTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectOfferExpiredActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_offer_expired',
    z.object({
      offer: ActivityDataTargetSchema,
    })
  )
