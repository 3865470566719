import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { AgencyDomainUpdateModelSchema } from './agency.models.ts'
import { BrandDomainUpdateModelSchema } from './brand.models.ts'
import { EmailAddressSchema } from './property.models.ts'
import { OnboardingIdSchema } from './unique-id.models.ts'
import { ExternalUserDomainUpdateModelSchema } from './user.models.ts'

/**
 * OnboardingVersion
 */
export const [
  OnboardingVersions,
  OnboardingVersionSchema,
  OnboardingVersionEnum,
] = EnumBuilder('v1')
export type OnboardingVersion = z.infer<typeof OnboardingVersionSchema>

/**
 * OnboardingState
 *   Keep these in the correct order that they appear through onboarding.
 */
export const [OnboardingStates, OnboardingStateSchema, OnboardingStateEnum] =
  EnumBuilder(
    'awaiting-user-type',
    'awaiting-company-info',
    'awaiting-personal-info',
    'in-review',
    'done'
  )
export type OnboardingState = z.infer<typeof OnboardingStateSchema>

/**
 * OnboardingSource
 */
export const [OnboardingSources, OnboardingSourceSchema, OnboardingSourceEnum] =
  EnumBuilder('registration', 'invitation')
export type OnboardingSourceName = z.infer<typeof OnboardingSourceSchema>

// no longer used, keeping for backwards compatibility
const AvatarSettingsSchema = z
  .object({
    selection: z.enum(['file', 'initials']).optional(),
    confirmed: z.boolean().optional(),
  })
  .optional()

/**
 * OnboardingCompanySchema
 */

export const OnboardingCompanySchema = z.union([
  BrandDomainUpdateModelSchema,
  AgencyDomainUpdateModelSchema,
])

export type OnboardingCompany = z.infer<typeof OnboardingCompanySchema>

/**
 * OnboardingDataSchema
 */
export const OnboardingDataSchema = z.object({
  user: ExternalUserDomainUpdateModelSchema.default({}),
  company: OnboardingCompanySchema.default({}),
  userAvatarSettings: AvatarSettingsSchema.optional(), // no longer used, keeping for backwards compatibility
})
export type OnboardingData = z.infer<typeof OnboardingDataSchema>

/**
 * OnboardingDomainSelectModel
 */
export const OnboardingDomainSelectModelSchema = z.object({
  onboardingId: OnboardingIdSchema,
  emailAddress: EmailAddressSchema,
  data: OnboardingDataSchema,
  version: OnboardingVersionSchema,
  state: OnboardingStateSchema,
  source: OnboardingSourceSchema,
})

export type OnboardingDomainSelectModel = z.infer<
  typeof OnboardingDomainSelectModelSchema
>

/**
 * OnboardingDomainInsertModel
 */
export const OnboardingDomainInsertModelSchema =
  OnboardingDomainSelectModelSchema.partial({
    onboardingId: true,
    version: true,
    data: true,
  })

export type OnboardingDomainInsertModel = z.infer<
  typeof OnboardingDomainInsertModelSchema
>

/**
 * OnboardingDomainUpdateModel
 */
export const OnboardingDomainUpdateModelSchema =
  OnboardingDomainSelectModelSchema.pick({
    version: true,
    state: true,
    data: true,
  }).partial()

export type OnboardingDomainUpdateModel = z.infer<
  typeof OnboardingDomainUpdateModelSchema
>

/**
 * OnboardingDomainSelectModelFilter
 */
const LowerCaseEmailAddressSchema = EmailAddressSchema.toLowerCase()

export const OnboardingDomainSelectModelFiltersSchema = z
  .object({
    onboardingId: ModelFilterSchema(OnboardingIdSchema),
    emailAddress: ModelFilterSchema(LowerCaseEmailAddressSchema),
    version: ModelFilterSchema(OnboardingVersionSchema),
    state: ModelFilterSchema(OnboardingStateSchema),
    source: ModelFilterSchema(OnboardingSourceSchema),
    search: z.string().min(1),
  })
  .partial()

export type OnboardingDomainSelectModelFilters = z.infer<
  typeof OnboardingDomainSelectModelFiltersSchema
>

export const InvitedUserInsertUpdateSchema = z.object({
  onboardingId: OnboardingIdSchema.optional(),
  emailAddress: EmailAddressSchema.optional(),
  data: OnboardingDataSchema,
  version: OnboardingVersionSchema.optional(),
  state: OnboardingStateSchema,
  source: OnboardingSourceSchema.optional(),
})

export type InvitedUserInsertUpdateType = z.infer<
  typeof InvitedUserInsertUpdateSchema
>
