import { ExternalUserTypeSchema } from '../user.models.ts'
import { ActivityDataTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

import { z } from 'zod'

export const ServiceNoteAddedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'service_note_added',
  z.object({
    service: ActivityDataTargetSchema,
    project: ActivityDataTargetSchema,
    noteType: ExternalUserTypeSchema,
  })
)
