import { format as dateFnsFormat, type Locale } from 'date-fns'

export type DateFormat =
  | 'long-date'
  | 'medium-date'
  | 'medium-date-alt'
  | 'medium-date-time'
  | 'short-time'
  | 'iso-like'

const dateFormatMap: Record<DateFormat, string> = {
  'long-date': 'MMMM d, y', // November 6, 2024
  'medium-date': 'MMM d, y', // Nov 6, 2024
  'medium-date-alt': 'dd MMM yyyy', // 06 Nov 2024
  'medium-date-time': 'MMM dd, yyyy • h:mm a', // Nov 06, 2024 • 1:34 PM
  'short-time': 'h:mm a', // 1:34 PM
  'iso-like': 'yyyy:MM:dd HH:mm:ss', // 2024:11:06 13:34:00
}

export const formatDate = (
  date: Date | undefined,
  format: DateFormat,
  locale?: Locale
) => (date ? dateFnsFormat(date, dateFormatMap[format], { locale }) : 'N/A')
