import { ActivityDataTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

import { z } from 'zod'

export const ProjectFileAddedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_file_added',
  z.object({
    file: ActivityDataTargetSchema,
    project: ActivityDataTargetSchema,
  })
)
