import { z } from 'zod'

import { NoteSchema, ProposalVersionSchema } from '../property.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const PreProductionMakerProposalSubmittedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'pre_production_maker_proposal_submitted',
    z.object({
      version: ProposalVersionSchema,
      note: NoteSchema,
    })
  )
