import { z } from 'zod'

import {
  type ContentTypeSchemaIndex,
  CreateFilesSchemas,
  type DownloadInfoSchemaIndex,
  type Infer,
  type KeySchemaIndex,
  type NameSchemaIndex,
  type UploadInfoSchemaIndex,
  type UploadRequestSchemaIndex,
  type UploadResultSchemaIndex,
} from './file-storage.utils.ts'
import {
  documentFileContentTypes,
  imageFileContentTypes,
  videoFileContentTypes,
} from './mime-types.ts'
import { NameSchema } from './property.models.ts'

/**
 * Avatar Schemas
 */

export const [
  AvatarKey,
  AvatarNameSchema,
  AvatarKeySchema,
  AvatarContentTypeSchema,
  AvatarDownloadInfoSchema,
  AvatarUploadRequestSchema,
  AvatarUploadResultSchema,
  AvatarUploadInfoSchema,
] = CreateFilesSchemas('avatars', imageFileContentTypes)

export type AvatarName = Infer<'avatars', NameSchemaIndex>
export type AvatarKey = Infer<'avatars', KeySchemaIndex>
export type AvatarDownloadInfo = Infer<'avatars', DownloadInfoSchemaIndex>
export type AvatarContentType = Infer<'avatars', ContentTypeSchemaIndex>
export type AvatarUploadRequest = Infer<'avatars', UploadRequestSchemaIndex>
export type AvatarUploadResult = Infer<'avatars', UploadResultSchemaIndex>
export type AvatarUploadInfo = Infer<'avatars', UploadInfoSchemaIndex>

/**
 * ProjectThumbnail Schemas
 */
export const [
  ProjectThumbnailKey,
  ProjectThumbnailNameSchema,
  ProjectThumbnailKeySchema,
  ProjectThumbnailContentTypeSchema,
  ProjectThumbnailDownloadInfoSchema,
  ProjectThumbnailUploadRequestSchema,
  ProjectThumbnailUploadResultSchema,

  ProjectThumbnailUploadInfoSchema,
] = CreateFilesSchemas('projectThumbnails', imageFileContentTypes)

export type ProjectThumbnailName = Infer<'projectThumbnails', NameSchemaIndex>
export type ProjectThumbnailKey = Infer<'projectThumbnails', KeySchemaIndex>
export type ProjectThumbnailDownloadInfo = Infer<
  'projectThumbnails',
  DownloadInfoSchemaIndex
>
export type ProjectThumbnailContentType = Infer<
  'projectThumbnails',
  ContentTypeSchemaIndex
>
export type ProjectThumbnailUploadRequest = Infer<
  'projectThumbnails',
  UploadRequestSchemaIndex
>
export type ProjectThumbnailUploadResult = Infer<
  'projectThumbnails',
  UploadResultSchemaIndex
>
export type ProjectThumbnailUploadInfo = Infer<
  'projectThumbnails',
  UploadInfoSchemaIndex
>

export const ProjectFileStorageKeySchema = z.custom<`projects/${string}`>(
  (val) => val.startsWith('projects/')
)
export type ProjectFileStorageKey = z.infer<typeof ProjectFileStorageKeySchema>

export const ProjectFileContentTypeSchema = z.enum([
  ...imageFileContentTypes,
  ...videoFileContentTypes,
  ...documentFileContentTypes,
])
export type ProjectFileContentType = z.infer<
  typeof ProjectFileContentTypeSchema
>

export const ProjectFileDownloadInfoSchema = z.object({
  key: ProjectFileStorageKeySchema,
  fileName: NameSchema,
})
export type ProjectFileDownloadInfo = z.infer<
  typeof ProjectFileDownloadInfoSchema
>

export const ProjectFileUploadInfoSchema = z.object({
  fileName: NameSchema,
  contentType: ProjectFileContentTypeSchema,
})
export type ProjectFileUploadInfo = z.infer<typeof ProjectFileUploadInfoSchema>
