import debugLogger from 'debug'

import type { LogLevel, LoggerFunction, LoggerInstance } from './log-types.ts'

const shouldLog = () =>
  process.env.NODE_ENV === 'test'
    ? process.env.SILENT === 'false'
    : process.env.SILENT !== 'true'

const createDebugLogger = (namespace?: string): LoggerFunction => {
  /**
   * The debug logger is used to log debug messages to the console
   * See https://www.npmjs.com/package/debug for more information
   */
  const debug = debugLogger(namespace ? `magicsky:${namespace}` : 'magicsky')
  debug.log = console.debug.bind(console)

  return (message, ...args) => {
    debug(`[debug] ${message}`, ...args)
  }
}

const createLevelLogger = (
  level: LogLevel,
  namespace?: string
): LoggerFunction => {
  const prefix = namespace ? `magicsky:${namespace} ` : ''
  return (message, args) =>
    shouldLog() && console[level](`${prefix}[${level}] ${message}`, args)
}

/**
 * This function is used to create a repository logger instance
 * @param namespace the namespace used by the debug logger
 * @returns a repository logger instance
 */
export const Logger = (namespace?: string): LoggerInstance => ({
  info: createLevelLogger('info', namespace),
  warn: createLevelLogger('warn', namespace),
  error: createLevelLogger('error', namespace),
  debug: createDebugLogger(namespace),
})

export const logger = Logger()
