import type { NonEmptyArray } from '@mntn-dev/utility-types'

export const isNonEmptyArray = <T>(array?: T[]): array is NonEmptyArray<T> => {
  return !!array && array.length > 0
}

export function assertNonEmptyArray<T>(
  array: T[],
  message?: string
): asserts array is NonEmptyArray<T> {
  if (!isNonEmptyArray(array)) {
    throw new Error(message ?? 'Expected non-empty array')
  }
}
