/*
 * Given an array with optional values (null or undefined), return a new array filtered of
 * the optional values and typed as to the non-optional type.
 */
export const defined = <T>(
  values: (T | undefined | null)[] | undefined | null
) => {
  return values
    ? values.filter(
        (value): value is T => value !== null && value !== undefined
      )
    : []
}
