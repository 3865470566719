import type { i18n as i18next } from 'i18next'

import { logger } from '@mntn-dev/logger'

/**
 * Changes the language of the i18n instance.
 * @param i18n - The i18n instance to change the language of.
 * @param lng - The language code to change to.
 * @throws An error if the language change fails.
 */
export function changeLanguage(i18n: i18next, lng: string) {
  i18n.changeLanguage(lng).catch((error) => {
    logger.error(`18n: failed to change language to ${lng}`, error)
  })
}
