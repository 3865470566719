import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'

import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  NonEmptyStringSchema,
  NoteSchema,
  ReviewRoundNumberSchema,
  TimestampSchema,
} from './property.models.ts'
import {
  ReviewIdSchema,
  RoundIdSchema,
  UserIdSchema,
} from './unique-id.models.ts'

/**
 * RoundStatus
 */
export const [RoundStatuses, RoundStatusSchema, RoundStatusEnum] = EnumBuilder(
  'in_progress',
  'changes_requested',
  'approved'
)
export type RoundStatus = z.infer<typeof RoundStatusSchema>

/**
 * RoundDomainSelectModel
 */
const RoundCommentSelectModelSchema = z
  .object({
    notes: NonEmptyStringSchema(),
    actorId: UserIdSchema,
    timestamp: TimestampSchema,
  })
  .partial()

export const RoundDomainSelectModelSchema = z.object({
  roundId: RoundIdSchema,
  reviewId: ReviewIdSchema,
  roundNumber: ReviewRoundNumberSchema,
  status: RoundStatusSchema,
  proposal: RoundCommentSelectModelSchema,
  feedback: RoundCommentSelectModelSchema,
})

export type RoundDomainSelectModel = z.infer<
  typeof RoundDomainSelectModelSchema
>

/**
 * RoundDomainInsertModel
 */
export const RoundDomainInsertModelSchema = RoundDomainSelectModelSchema.omit({
  status: true, // Always inserted as in_progress
  proposal: true,
  feedback: true,
}).partial({
  roundId: true,
})

export type RoundDomainInsertModel = z.infer<
  typeof RoundDomainInsertModelSchema
>

/**
 * RoundDomainUpdateModel
 */
const RoundCommentUpdateModelSchema = z
  .object({
    notes: NoteSchema.nullable(),
    actorId: UserIdSchema,
    timestamp: TimestampSchema,
  })
  .partial()

export const RoundDomainUpdateModelSchema = RoundDomainSelectModelSchema.pick({
  status: true,
})
  .merge(
    z.object({
      proposal: RoundCommentUpdateModelSchema,
      feedback: RoundCommentUpdateModelSchema,
    })
  )
  .partial()

export type RoundDomainUpdateModel = z.infer<
  typeof RoundDomainUpdateModelSchema
>

/**
 * RoundDomainSelectModelFilter
 */
export const RoundDomainSelectModelFiltersSchema = z
  .object({
    roundId: ModelFilterSchema(RoundIdSchema),
    reviewId: ModelFilterSchema(ReviewIdSchema),
    roundNumber: ModelFilterSchema(ReviewRoundNumberSchema),
    status: ModelFilterSchema(RoundStatusSchema),
  })
  .partial()

export type RoundDomainSelectModelFilters = z.infer<
  typeof RoundDomainSelectModelFiltersSchema
>
