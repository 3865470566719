import { z } from 'zod'

import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  CompanyNameSchema,
  NonEmptyStringSchema,
  WebsiteUrlSchema,
} from './property.models.ts'
import { AgencyIdSchema } from './unique-id.models.ts'

/**
 * AgencyDomainSelectModel
 */
export const AgencyDomainSelectModelSchema = z.object({
  agencyId: AgencyIdSchema,
  companyName: CompanyNameSchema,
  companyWebsiteUrl: WebsiteUrlSchema.optional(),
})

export type AgencyDomainSelectModel = z.infer<
  typeof AgencyDomainSelectModelSchema
>

/**
 * AgencyDomainInsertModel
 */
export const AgencyDomainInsertModelSchema =
  AgencyDomainSelectModelSchema.partial({
    agencyId: true,
  })

export type AgencyDomainInsertModel = z.infer<
  typeof AgencyDomainInsertModelSchema
>

/**
 * AgencyDomainUpdateModel
 */
export const AgencyDomainUpdateModelSchema = AgencyDomainSelectModelSchema.omit(
  {
    agencyId: true,
  }
)
  .extend({
    companyWebsiteUrl:
      AgencyDomainSelectModelSchema.shape.companyWebsiteUrl.nullable(),
  })
  .partial()

export type AgencyDomainUpdateModel = z.infer<
  typeof AgencyDomainUpdateModelSchema
>

/**
 * AgencyDomainSelectModelFilters
 */
export const AgencyDomainSelectModelFiltersSchema = z
  .object({
    agencyId: ModelFilterSchema(AgencyIdSchema),
    // When filtering, transform company names to lower case so the
    // "lower(agencies.company_name)" index can be used.
    companyName: ModelFilterSchema(CompanyNameSchema.toLowerCase()),
    search: NonEmptyStringSchema(),
  })
  .partial()

export type AgencyDomainSelectModelFilters = z.infer<
  typeof AgencyDomainSelectModelFiltersSchema
>
