import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { NonEmptyStringSchema, TranslationsSchema } from './property.models.ts'
import { TagIdSchema } from './unique-id.models.ts'

/**
 * Public tag categories
 */
export const [
  PublicTagCategories,
  PublicTagCategorySchema,
  PublicTagCategoryEnum,
] = EnumBuilder(
  'certification',
  'industry',
  'language',
  'location',
  'platform',
  'skill'
)
export type PublicTagCategory = z.infer<typeof PublicTagCategorySchema>

/**
 * TagCategory
 */
export const [TagCategories, TagCategorySchema, TagCategoryEnum] = EnumBuilder(
  ...PublicTagCategories,
  'ethnicity',
  'gender',
  'uncategorized'
)
export type TagCategory = z.infer<typeof TagCategorySchema>

/**
 * TagTranslations
 */
export const TagTranslationsSchema = TranslationsSchema('value', 'description')
export type TagTranslations = z.infer<typeof TagTranslationsSchema>

/**
 * TagDomainSelectModel
 */
export const TagDomainSelectModelSchema = z.object({
  tagId: TagIdSchema,
  category: TagCategorySchema,
  value: NonEmptyStringSchema(128),
  description: NonEmptyStringSchema().optional(),
  translations: TagTranslationsSchema.optional(),
})

export type TagDomainSelectModel = z.infer<typeof TagDomainSelectModelSchema>

/**
 * TagDomainInsertModel
 */
export const TagDomainInsertModelSchema = TagDomainSelectModelSchema.partial({
  tagId: true,
})

export type TagDomainInsertModel = z.infer<typeof TagDomainInsertModelSchema>

/**
 * TagDomainUpdateModel
 */
export const TagDomainUpdateModelSchema = TagDomainSelectModelSchema.omit({
  tagId: true,
}).partial()

export type TagDomainUpdateModel = z.infer<typeof TagDomainUpdateModelSchema>

/**
 * TagDomainSelectModelFilter
 */
export const TagDomainSelectModelFiltersSchema = z
  .object({
    tagId: ModelFilterSchema(TagIdSchema),
    category: ModelFilterSchema(TagCategorySchema),
    value: ModelFilterSchema(
      TagDomainSelectModelSchema.shape.value.toLowerCase()
    ),
  })
  .partial()

export type TagDomainSelectModelFilters = z.infer<
  typeof TagDomainSelectModelFiltersSchema
>

/**
 * TagFilter
 */
export const TagFilterSchema = z.record(
  TagCategorySchema,
  ModelFilterSchema(TagIdSchema)
)

export type TagFilter = z.infer<typeof TagFilterSchema>
