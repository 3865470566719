import { z } from 'zod'

/**
 *
 * @param field the schema of the model field to be filtered
 * @returns A schema that accepts a single value or a non-empty array of values
 */
export const ModelFilterSchema = <FieldSchema extends z.ZodTypeAny>(
  field: FieldSchema
) => field.or(z.array(field).nonempty())
