type CurrencyConfig = {
  fractionDigits?: number
  trailingZeroDisplay?: 'auto' | 'stripIfInteger'
}

const defaults: Required<CurrencyConfig> = {
  fractionDigits: 2,
  trailingZeroDisplay: 'stripIfInteger',
}

export const currency = (val: number, config: CurrencyConfig = defaults) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: config.fractionDigits,
    trailingZeroDisplay: config.trailingZeroDisplay,
  }).format(val)
}
