import { z } from 'zod'

import {
  CompanyNameSchema,
  NonEmptyStringSchema,
} from '../models/property.models.ts'
import { UserDomainQueryModelSchema } from '../models/query.models.ts'
import {
  UserDomainSelectModelWithDeactivateStatusSchema,
  UserTypeSchema,
} from '../models/user.models.ts'

export const UserListItemServiceModelSchema =
  UserDomainSelectModelWithDeactivateStatusSchema.pick({
    userId: true,
    userType: true,
    firstName: true,
    lastName: true,
    displayName: true,
    emailAddress: true,
    isActive: true,
    canBeDeactivated: true,
  }).extend({
    companyName: CompanyNameSchema.optional(),
  })

export type UserListItemServiceModel = z.infer<
  typeof UserListItemServiceModelSchema
>

const UserListAclSchema = z.object({
  canDeactivate: z.boolean(),
})

export type UserListAcl = z.infer<typeof UserListAclSchema>

export const UserListServiceModelSchema = z.object({
  acl: UserListAclSchema,
  items: z.array(UserListItemServiceModelSchema),
  // Add pagination metadata here (cursor, etc)
})

export type UserListServiceModel = z.infer<typeof UserListServiceModelSchema>

export const UserListOrderByColumnSchema =
  UserDomainQueryModelSchema.keyof().extract([
    'firstName',
    'lastName',
    'emailAddress',
    'userType',
  ])

export type UserListOrderByColumn = z.infer<typeof UserListOrderByColumnSchema>

export const UserListOrderByDirectionSchema = z.enum(['asc', 'desc'])

export type UserListOrderByDirection = z.infer<
  typeof UserListOrderByDirectionSchema
>

export const UserListOrderBySchema = z.object({
  column: UserListOrderByColumnSchema,
  direction: UserListOrderByDirectionSchema,
})

export type UserListOrderBy = z.infer<typeof UserListOrderBySchema>

export const UserMaxSearchLength = 256

export const UserSearchSchema = NonEmptyStringSchema(UserMaxSearchLength)

export const UserListWhereSchema = z.object({
  search: UserSearchSchema.optional(),
  userType: z.array(UserTypeSchema).nonempty().optional(),
  includeDeactivated: z.boolean().optional(),
})

export type UserListWhere = z.infer<typeof UserListWhereSchema>

export const UserListLimitMax = 1000

export const UserListLimitSchema = z
  .number()
  .int()
  .positive()
  .max(UserListLimitMax)
