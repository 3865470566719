/**
 * Generic assert function to ensure a condition is true.
 * @param condition - The condition to check.
 * @param message - The message to throw if the condition is false.
 */
export function assert<T>(
  condition: T | undefined | null,
  message: string
): asserts condition is T {
  if (!condition) {
    throw new Error(message)
  }
}

//Generic type narrowing function
export const assertArgumentType =
  <Type extends Arg, Arg = unknown>(
    assertFn: (arg: Arg) => asserts arg is Type
  ) =>
  (value: Arg) => {
    assertFn(value)
    return value
  }
