import {
  archiveMimeTypes,
  audioMimeTypes,
  codeMimeTypes,
  documentMimeTypes,
  fontMimeTypes,
  imageMimeTypes,
  otherMimeTypes,
  videoMimeTypes,
} from './mime-data.ts'
import {
  type FileContentType,
  type FileExtension,
  archiveFileContentTypes,
  archiveFileExtensions,
  audioFileContentTypes,
  audioFileExtensions,
  codeFileContentTypes,
  codeFileExtensions,
  documentFileContentTypes,
  documentFileExtensions,
  fontFileContentTypes,
  fontFileExtensions,
  imageFileContentTypes,
  imageFileExtensions,
  otherFileContentTypes,
  otherFileExtensions,
  videoFileContentTypes,
  videoFileExtensions,
} from './mime-types.ts'

type FileKindRecord<
  ContentType extends FileContentType,
  Extension extends FileExtension,
> = Record<
  string,
  {
    contentTypes: Array<ContentType>
    description: string
    extensions: Array<Extension>
    map: Partial<Record<ContentType, Array<Extension>>>
  }
>

export const FileKindInfo = {
  document: {
    contentTypes: documentFileContentTypes,
    description: 'Documents',
    extensions: documentFileExtensions,
    map: documentMimeTypes,
  },
  image: {
    contentTypes: imageFileContentTypes,
    description: 'Images',
    extensions: imageFileExtensions,
    map: imageMimeTypes,
  },
  audio: {
    contentTypes: audioFileContentTypes,
    description: 'Audio',
    extensions: audioFileExtensions,
    map: audioMimeTypes,
  },
  video: {
    contentTypes: videoFileContentTypes,
    description: 'Video',
    extensions: videoFileExtensions,
    map: videoMimeTypes,
  },
  archive: {
    contentTypes: archiveFileContentTypes,
    description: 'Archives',
    extensions: archiveFileExtensions,
    map: archiveMimeTypes,
  },
  font: {
    contentTypes: fontFileContentTypes,
    description: 'Fonts',
    extensions: fontFileExtensions,
    map: fontMimeTypes,
  },
  code: {
    contentTypes: codeFileContentTypes,
    description: 'Code',
    extensions: codeFileExtensions,
    map: codeMimeTypes,
  },
  raw: {
    contentTypes: [],
    description: '',
    extensions: [],
    map: {},
  },
  other: {
    contentTypes: otherFileContentTypes,
    description: 'Other',
    extensions: otherFileExtensions,
    map: otherMimeTypes,
  },
  unknown: {
    contentTypes: [],
    description: '',
    extensions: [],
    map: {},
  },
} as const satisfies FileKindRecord<FileContentType, FileExtension>

export type FileKind = keyof typeof FileKindInfo
