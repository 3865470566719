import { ActivityDataTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

import { z } from 'zod'

export const ProjectMatchedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_matched',
  z.object({
    project: ActivityDataTargetSchema,
  })
)
